import { Link } from '~components'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images } from '~data'
import Footer from "./style"
export default function FooterTwo(){
return(
    <Footer>
    <Container>
        <Footer.Copyright>
        <Footer.CopyrightText>© 2024 Zenzo By StellarTech Software Labs. All Rights Reserved</Footer.CopyrightText>
        </Footer.Copyright>
    </Container>
    </Footer>
)
}